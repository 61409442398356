/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';

import Layout from '../components/layout'
import { graphql } from 'gatsby'

import sendEvent from '../helpers/sendAnalytics';


// Import paragraphs
import CenteredTextContent from '../paragraphs/centered_text_content.js';
import ImageLeftTextRightHeader from '../paragraphs/image_left_text_right_header.js';
import ImageLeftTextRight from '../paragraphs/image_left_text_right.js';
import TextLeftImageRight from '../paragraphs/text_left_image_right.js';
import CenteredTitleWithSubheader from '../paragraphs/centered_title_with_subheader.js';
import FullWidthImageImg from '../paragraphs/full_width_image_img.js';
import HeaderBanner from '../paragraphs/header_banner.js';
import ImageLeftTextRightBackground from '../paragraphs/image_left_text_right_background.js';
import TwoColumnContent from '../paragraphs/two_column_content.js';
import TextLeftImageRightBackground from '../paragraphs/text_left_image_right_background.js';

class AboutPage extends React.Component {
  sendLink(event){
    sendEvent({
      ga: {
        category: 'About_Scotties_Engagement',
        action: 'External_Link',
        label: event.target.href
      }
    })
  }

  render() {
    const data = this.props.data.nodePage
    const retailers = this.props.data.allNodeStore.edges
    return (
      <Layout className="about-page page-wrapper">
        <SEO meta={data.field_meta_tags} />
        <Header retailers={retailers}></Header>
        <div style={{clear:'both'}} />
        <main>
          <h1 className="sr-only">About Scotties Facial</h1>
          {data.relationships.field_paragraph.map(paragraph => {
            switch(paragraph.__typename) {
              case 'paragraph__image_left_text_right_header':
                return (
                  <ImageLeftTextRightHeader key={paragraph.id} data={paragraph} />
                );
                break;
              case 'paragraph__header_banner':
                return (
                  <HeaderBanner key={paragraph.id} data={paragraph} />
                );
                break;
              case 'paragraph__centered_title_with_subheader':
                return (
                  <CenteredTitleWithSubheader key={paragraph.id} data={paragraph} />
                );
                break;
              case 'paragraph__full_width_image':
                return (
                  <FullWidthImageImg key={paragraph.id} data={paragraph} />
                );
                break;
              case 'paragraph__image_left_text_right_background':
                return (
                  <ImageLeftTextRightBackground key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                );
                break;
              case 'paragraph__text_left_image_right_background':
                return (
                  <TextLeftImageRightBackground key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                );
                break;
              case 'paragraph__two_column_centered_text':
                return (
                  <TwoColumnContent key={paragraph.id} data={paragraph} blogs={this.props.data.allNodeBlog} />
                );
                break;
            }
          })}
        </main>

        <div className="row-text-with-two-columns">
          <div className="row-text-with-two-columns-title intro-bold">Frequently Asked&nbsp; Questions</div>
          <div className="row-text-with-two-columns-wrapper">
            {data.relationships.field_frequently_asked_questions.map(question => {
              return (
                <div className="row-text-with-two-columns-row">
                  <div className="row-text-with-two-columns-row-column">
                    <div className="row-column-left-text intro-bold" dangerouslySetInnerHTML={{__html: question.field_faq_title.value}}></div>
                  </div>
                  <div className="row-text-with-two-columns-row-column">
                    <div className="row-column-right-text section-body-text" onClick={(event) => { this.sendLink(event); }} dangerouslySetInnerHTML={{__html: question.field_answer.value}}></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <GeneralFooter />
      </Layout>
    )
  }
}

export default AboutPage

export const query = graphql`
query About {
  nodePage(uuid: {eq:"4279aa27-a6c3-47a1-bdec-b8c2a2e8e4d4"}) {
    id
    title
    field_meta_tags
    relationships {
      field_frequently_asked_questions {
        ... on paragraph__frequently_asked_questions {
          field_faq_title {
            value
          }
          field_answer {
            value
          }
        }
      }
      field_paragraph {
        __typename
        ... on paragraph__image_left_text_right_header {
          id
          field_heading_title {
            value
          }
          field_sub_title
          field_body_text {
            value
          }
          field_list_items
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width:730, quality: 85) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__header_banner {
          id
          relationships {
            field_header_banner_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
        ... on paragraph__centered_title_with_subheader {
          id
          field_centered_title
          field_centered_subheading {
            value
          }
        }
        ... on paragraph__full_width_image {
          id
          relationships {
            field_full_width_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1600, quality: 80) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__text_left_image_right_background {
          field_title
          field_body_text {
            value
          }
          field_forest_cycle_label
          field_forest_cycle_value
          field_forest_research_label
          field_forest_research_value
          field_conservation_sites_label
          field_conservation_sites_value
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
        ... on paragraph__image_left_text_right_background {
          id
          field_title
          field_body_text {
            value
          }
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
            field_bullet_points {
              id
              field_bullet_points_left {
                value
              }
              field_bullet_points_right
            }
          }
        }
        ... on paragraph__two_column_centered_text {
          relationships {
            field_two_column_content {
              id
              field_link {
                uri
                title
              }
              field_body_text {
                value
              }
            }
          }
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
